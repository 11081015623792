<template>
  <div class="TasksList">

    <!-- ERROR MODAL -->
    <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal"  hide-footer title="Une erreur s'est produite">
      <div class="text-center">
        <p>Une erreur s'est produite, veuillez actualiser la page et réessayer</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
      </div>
    </b-modal>

    <!-- DELETED TASK MODAL -->
    <b-modal ref="deletedTaskModal" id="deletedTaskModal"  hide-footer title="Votre tâche a bien été supprimée">
      <div class="text-center">
        <p>Votre tâche a bien été supprimée</p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('deletedTaskModal')">Fermer</b-button>
      </div>
    </b-modal>

    <!-- DELETED ALL TASKs MODAL -->
    <b-modal ref="deletedAllTasksModal" id="deletedAllTasksModal"  hide-footer title="Vos tâches ont bien été supprimées">
      <div class="text-center">
        <p>Toutes vos tâches ont bien été supprimées</p>
        <p>
          <strong>Message de log du script : </strong><br> 
          {{deleteAllTaskLogMessage}}
        </p>
        <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('deletedAllTasksModal')">Fermer</b-button>
      </div>
    </b-modal>


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="#F9BF3B">
    </loading>

    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Vos crawls</h1>
        <div class="form_div">
          <div class="form_content">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col-sm-6">
                    <h3>Taches actives</h3>
                  </div>
                  <div class="col-sm-6 text-right">
                    <b-button variant="outline-danger" size="sm" pill
                      @click="deleteAllTasks()">
                      Supprimer tout
                    </b-button>
                    <b-button variant="outline-secondary" size="sm" pill class="ml-2"
                      @click="getAllActiveTasks(); getAllReservedTasks()">
                      Actualiser
                    </b-button>
                  </div>
                </div>

                <h6> <strong>Tâches actives HTML ou PDF</strong></h6>
                <span v-for="task in activeTasks['celery@default_worker']" v-bind:key="task.id">
                  <strong>URL : </strong>{{task.args[1]}}
                  <b-button
                    variant="outline-danger" class="ml-1" size="sm"
                    @click='deleteTask(task.id)'>
                    <v-icon name="trash-alt"/>
                  </b-button> <br>
                </span>

                <h6 class="mt-4"> <strong>Tâches actives selenium</strong></h6>
                <span v-for="task in activeTasks['celery@selenium_worker']" v-bind:key="task.id">
                  <strong>URL : </strong>{{task.args[2]}}
                  <b-button
                    variant="outline-danger" class="ml-1" size="sm"
                    @click='deleteTask(task.id)'>
                    <v-icon name="trash-alt"/>
                  </b-button> <br>
                </span>

                <hr>
                <h3>Taches en attente</h3>
                <h6> <strong>Tâches en attente HTML ou PDF</strong></h6>
                <span v-for="task in reservedTasks['celery@default_worker']" v-bind:key="task.id">
                  <strong>URL : </strong>{{task.args[1]}}
                  <b-button
                    variant="outline-danger" class="ml-1" size="sm"
                    @click='deleteTask(task.id)'>
                    <v-icon name="trash-alt"/>
                  </b-button> <br>
                </span>

                <h6 class="mt-4"> <strong>Tâches en attente selenium</strong></h6>
                <span v-for="task in reservedTasks['celery@selenium_worker']" v-bind:key="task.id">
                  <strong>URL : </strong>{{task.args[2]}}
                  <b-button
                    variant="outline-danger" class="ml-1" size="sm"
                    @click='deleteTask(task.id)'>
                    <v-icon name="trash-alt"/>
                  </b-button> <br>
                </span>
              </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import { APITask } from '@/api/APITask'
import formTitleComponent from '@/components/formTitleComponent'

const apiTask = new APITask()

export default {
  name: 'TasksList',
  components: {
    formTitleComponent,
    Loading,
  },
  data: function () {
    return {
      activeTasks: [],
      isActiveTasksLoading: false,

      reservedTasks: [],
      isReservedTasksLoading: false,

      isDeletingTask: false,
      deleteAllTaskLogMessage: '',
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isActiveTasksLoading || this.isReservedTasksLoading || this.isDeletingTask) {
        return true
      }
      return false
    },
  },
  created: function() {
    this.getAllActiveTasks()
    this.getAllReservedTasks()
  },
  methods: {
    getAllActiveTasks () {
      this.isActiveTasksLoading = true
      apiTask.getAllActiveTasks(this.token)
      .then((result) => {
        this.activeTasks = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('TasksList getAllActiveTasks API Error : ' + String(error))
      })
      .finally(() => {
        this.isActiveTasksLoading = false
      })
    },
    getAllReservedTasks () {
      this.isReservedTasksLoading = true
      apiTask.getAllReservedTasks(this.token)
      .then((result) => {
        this.reservedTasks = result.data
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('TasksList getAllActiveTasks API Error : ' + String(error))
      })
      .finally(() => {
        this.isReservedTasksLoading = false
      })
    },

    deleteAllTasks () {
      this.isDeletingTask = true
      apiTask.deleteAllTask(this.token)
      .then((result) => {
        this.deleteAllTaskLogMessage = result.data['output']
        this.$refs.deletedAllTasksModal.show()
        this.getAllActiveTasks()
        this.getAllReservedTasks()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('TasksList deleteAllTasks API Error : ' + String(error))
      })
      .finally(() => {
        this.isDeletingTask = false
      })
    },

    deleteTask (task_id) {
      this.isDeletingTask = true
      apiTask.deleteTask(this.token, task_id)
      .then(() => {
        this.$refs.deletedTaskModal.show()
        this.getAllActiveTasks()
        this.getAllReservedTasks()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('TasksList deleteTask API Error : ' + String(error))
      })
      .finally(() => {
        this.isDeletingTask = false
      })
    },
  }
}
</script>
